
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Menu, Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { ClockIcon, HeartIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import Head from 'next/head';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRouter } from 'next/router';
import { SubscriptionPlanCard } from '../components';
import PersonalTraining from '../public/images/home/personal-training.svg';
import WorkingOut from '../public/images/home/working-out.svg';
import { UserIcon, CalendarIcon, CheckIcon } from '@heroicons/react/24/outline';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
import { useCompany } from './company';
import { getYear } from 'date-fns';

export const setLocaleCookie = (locale: string) => {
  console.log(locale);
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
};

const features = [
  {
    name: 'personal-account',
    description: 'personal-account-text',
    icon: UserIcon,
    image: 'select-company',
  },
  {
    name: `get-access`,
    description: `get-access-text`,
    icon: CalendarIcon,
    image: 'view-the-calendar',
  },
  {
    name: 'daily-training-session',
    description: `daily-training-session-text`,
    icon: CheckIcon,
    image: 'book-the-slot',
  },
];

const userNavigation = [
  { name: 'Your Profile', href: '/profile' },
  { name: 'Sign out', href: '/api/auth/logout' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Index() {
  const { user } = useUser();
  const router = useRouter();
  const { t, lang } = useTranslation('home');
  const { locales } = router;

  useEffect(() => {
    if (router.query.customerCreationFailure) {
      toast.error('Something went wrong, please try to login again.');
    }
  }, []);

  const {
    data: company,
    isLoading: isCompanyLoading,
    isValidating,
  } = useCompany('/companies/public');

  if (isCompanyLoading) {
    return '';
  }

  return (
    <div className="bg-white">
      <Head>
        <link
          rel="manifest"
          href={`/manifests/${company.alias}.manifest.json`}
        />
        <title>
          {company?.alias || process.env.NEXT_PUBLIC_COMPANY_ALIAS} - slotit.io
        </title>
      </Head>
      <ToastContainer />
      <header className="shadow">
        <div className="flex justify-between items-center mx-auto px-4 h-20">
          <div className="flex justify-between lg:w-0 lg:flex-1">
            <div>
              <Link href="/" legacyBehavior>
                <a className="flex items-center flex-shrink-0">
                  {company?.logoUrl ? (
                    <Image
                      src={company?.logoUrl}
                      width={60}
                      height={60}
                      alt=""
                      className="rounded-full"
                    />
                  ) : (
                    <ClockIcon
                      className="h-12 w-12 text-indigo-600"
                      aria-hidden="true"
                    />
                  )}
                  <span className="ml-2 font-medium text-gray-900 hidden lg:flex">
                    {company?.name || process.env.NEXT_PUBLIC_COMPANY_ALIAS}
                  </span>
                </a>
              </Link>
            </div>
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            {user?.name ? (
              <>
                <Menu as="div" className="ml-3 relative">
                  <div className="flex items-center">
                    <div className="mr-2">
                      <Link href="/calendar" legacyBehavior>
                        <a
                          type="button"
                          className="flex items-center bg-white p-1 text-sm rounded-full text-indigo-600 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <span>{t('common:calendar')}</span>
                          <ArrowRightIcon
                            className="h-4 w-4 text-indigo-600 ml-1"
                            aria-hidden="true"
                          />
                        </a>
                      </Link>
                    </div>
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex flex-shrink-0 items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <>
                          <span className="sr-only">Open user menu</span>
                          <span className="whitespace-nowrap px-2 hidden sm:block font-medium text-gray-500 hover:text-gray-900">
                            {user.name}
                          </span>
                          <Image
                            className="inline-block h-9 w-9 rounded-full"
                            src={user.picture}
                            alt={user.name}
                            width="32"
                            height="32"
                          />
                        </>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-50 origin-top-right absolute right-0 mr-24 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link href={item.href} legacyBehavior>
                                <a
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </div>
                </Menu>
              </>
            ) : (
              // eslint-disable-next-line @next/next/no-html-link-for-pages
              <>
                <a
                  href={`/api/auth/login`}
                  className="p-1 px-2.5 rounded-full text-indigo-500 hover:text-indigo-400 ring-2 ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-3 capitalize"
                >
                  {t('common:login')}
                </a>
                <a
                  href={`/api/auth/login?signup=true`}
                  className="p-1 px-2.5 rounded-full text-indigo-500 hover:text-indigo-400 focus:outline-none ring-2 ring-indigo-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize"
                >
                  {t('common:signup')}
                </a>
              </>
            )}
            <div className="ml-4">
              <select
                id="language"
                name="language"
                className="block w-full rounded-md border-gray-300 py-1.5 pl-2 pr-8 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={lang}
                onChange={async (event) => {
                  await setLanguage(event.target.value);
                  setLocaleCookie(event.target.value);
                }}
              >
                {locales.map((lang) => (
                  <option key={lang}>{lang}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 pb-12 sm:pb-24">
          <div className="text-center">
            <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
              <span className="block xl:inline">{t('title')}</span>{' '}
              <span className="block text-indigo-600 xl:inline">
                {t('online')}
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              {t('subtitle')}
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link href="/calendar" legacyBehavior>
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    {t('common:view-calendar')}
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="flex justify-center">
              <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-4xl"
                src={`images/${company?.alias}.png`}
                alt={company?.alias}
              />
            </div>
          </div>
        </div>

        <div className="py-12 sm:py-24 bg-gray-100">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                <span className="block xl:inline">{t('how-it-works')}</span>
              </h2>
            </div>
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt>
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                      {t(feature.name)}
                    </p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {t(feature.description)}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-12 lg:mt-20">
              <div className="rounded-md shadow">
                <Link href="/calendar" legacyBehavior>
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    {t('common:view-calendar')}
                  </a>
                </Link>
              </div>
            </div>
            <div className="flex justify-center mt-6 sm:mt-12">
              <WorkingOut className="max-w-sm h-auto sm:max-w-lg" />
            </div>
          </div>
        </div>

        <div className="py-12 sm:py-24">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                <span className="block xl:inline">
                  {t('subscription-plans')}
                </span>
              </h2>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl">
                {t('available-subscription-plans')}{' '}
                <a
                  className="underline"
                  href={`tel:${company?.user?.phoneNumber}`}
                >
                  {t('common:call-us')}
                </a>{' '}
                {t('or-send-us-an')}{' '}
                <a
                  className="underline"
                  href={`mailto:${company?.user?.email}`}
                >
                  {t('common:email')}
                </a>{' '}
                {t('to-share-more')}
              </p>
            </div>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {company?.plans
                ?.sort((a, b) =>
                  parseInt(a.price) > parseInt(b.price)
                    ? 1
                    : parseInt(b.price) > parseInt(a.price)
                    ? -1
                    : 0
                )
                ?.map((plan) => (
                  <SubscriptionPlanCard plan={plan} key={plan.id}>
                    <Link
                      href="/subscriptions?page=subscriptions"
                      legacyBehavior
                    >
                      <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {t('send-request')}
                      </a>
                    </Link>
                  </SubscriptionPlanCard>
                ))}
            </div>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-12 lg:mt-20">
              <div className="rounded-md shadow">
                <Link href="/subscriptions?page=subscriptions" legacyBehavior>
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    {t('view-subscription-plans')}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="py-12 sm:py-24 bg-gray-100 overflow-hidden">
          <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                <span className="block xl:inline">{t('company-details')}</span>
              </h2>
            </div>
            <div className="mt-10">
              <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                <div>
                  <div className="relative">
                    <dt>
                      <div className="flex space-x-2 items-center">
                        <div className="flex items-center justify-center h-12 w-12 text-white">
                          {company?.logoUrl ? (
                            <Image
                              src={company?.logoUrl}
                              width={60}
                              height={60}
                              alt={company?.logoUrl}
                              className="rounded-full"
                            />
                          ) : (
                            <ClockIcon
                              className="h-12 w-12 text-indigo-600"
                              aria-hidden="true"
                            />
                          )}
                        </div>

                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                          {company?.name}
                        </p>
                      </div>
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      <div>
                        <div>
                          <span className="text-sm text-gray-400 inline-block mr-1">
                            {t('common:email')}
                          </span>
                          <a href={`mailto:${company?.user?.email}`}>
                            {company?.user?.email}
                          </a>
                        </div>
                        <div>
                          <span className="text-sm text-gray-400 inline-block mr-1">
                            {t('common:phone-number')}
                          </span>
                          <a href={`tel:${company?.phoneNumber}`}>
                            {company?.phoneNumber}
                          </a>
                        </div>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-400 inline-block mr-1">
                          {t('common:address')}
                        </span>
                        <span>
                          <span>{company?.streetAddress}, </span>
                          <span>{company?.city}, </span>
                          <span>{company?.postalCode}</span>
                        </span>
                      </div>
                      <div className="mt-6">{company?.about}</div>
                    </dd>
                  </div>
                </div>
                <div className="flex">
                  <PersonalTraining className="h-auto" />
                </div>
              </dl>
            </div>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-12 lg:mt-20">
              <div className="rounded-md shadow">
                <Link href="/company?page=company" legacyBehavior>
                  <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                    {t('view-company-details')}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-gray-900">
        <div className="mx-auto max-w-md py-16 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <p className="text-center text-base text-gray-400">
            &copy;{getYear(new Date())}{' '}
            {company?.alias || process.env.NEXT_PUBLIC_COMPANY_ALIAS}. All
            rights reserved.
          </p>
          <div>
            <p className="mt-4 text-center text-sm text-gray-400 flex justify-center items-center">
              powered with{' '}
              <HeartIcon
                className="h-5 w-5 text-indigo-600 mx-1"
                aria-hidden="true"
              />
              by{' '}
              <a className="underline ml-1" href={'https://slotit.io'}>
                slotit.io
              </a>
              .{' '}
              <Link href="/privacy-policy" legacyBehavior>
                <a className="underline ml-1">
                  Terms of Service & Privacy Policy
                </a>
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Index;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  