
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react';
import { withPageAuthRequired } from '@auth0/nextjs-auth0';
import { Layout } from '../components';
import Image from 'next/image';
import { fetcher } from './calendar';
import useSWR from 'swr';

export function useCompany(address: string) {
  const { data, error, isValidating } = useSWR(address, fetcher);

  return {
    data,
    isValidating,
    isLoading: !error && !data,
    isError: error,
  };
}

function Profile() {
  const { data: company, isLoading, isValidating } = useCompany('/companies');

  return (
    company && (
      <Layout title="Company | slotit.io" company={company}>
        <div className="max-w-3xl">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 flex">
              <div className="flex-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Company Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  These are the company details.
                </p>
              </div>
              <div></div>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Company</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      <div>
                        {company?.logoUrl && (
                          <Image
                            className="inline-block h-9 w-9 rounded-full mr-3"
                            src={company?.logoUrl}
                            alt={company?.name}
                            width="32"
                            height="32"
                          />
                        )}
                      </div>
                      <div className="ml-2">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                          {company?.name}
                        </p>
                      </div>
                    </div>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      <span>{company?.user?.email}</span>
                    </div>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      <a href={`tel:${company?.user?.phoneNumber}`}>
                        {company?.user?.phoneNumber}
                      </a>
                    </div>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center space-x-0.5">
                      <span>{company?.streetAddress},</span>
                      <span>{company?.city},</span>
                      <span>{company?.postalCode}</span>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </Layout>
    )
  );
}

const __Page_Next_Translate__ = withPageAuthRequired(Profile);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  